import dayjs from 'dayjs';
import { useGetCurrentUserQuery, useGetSettingsQuery } from 'gql';
import { useCurrentUserRoles } from 'hooks/useCurrentUserRoles';
import { TimeRange } from 'modules/home/components/DropdownFilter';
import { getStartOfCurrentFiscalYear } from 'modules/reports/utils';
import { useMemo } from 'react';
import { useGetDropdownTeams } from './useGetDropdownTeams';

export type Filter = {
  min: string,
  max: string,
  createdBy: string
  teamsId: number[]
}

export const useGetActivitiesFilter = (timeRangeFilter: TimeRange, teamFilter?: string) => {
  const { isAdministrator, isAnalyst } = useCurrentUserRoles();
  const { teams } = useGetDropdownTeams();

  const { data: settingsData } = useGetSettingsQuery();
  const fiscalYearSetting = settingsData?.settings.at(0)?.fiscalYearStart;
  const fiscalYearStart = fiscalYearSetting != null
    ? getStartOfCurrentFiscalYear(fiscalYearSetting)
    : dayjs().startOf('year');

  const { data: currentUser } = useGetCurrentUserQuery();

  const activitiesFilter: Filter = useMemo(() => {
    const filter: Filter = {
      min: dayjs().startOf('day').utc().toISOString(),
      max: dayjs().endOf('day').utc().toISOString(),
      createdBy: currentUser?.me.id ?? '',
      teamsId: []
    };

    switch (timeRangeFilter) {
      case TimeRange.last7Days:
        filter.min = dayjs().subtract(7, 'day').startOf('day').utc().toISOString();
        break;
      case TimeRange.lastMonth:
        filter.min = dayjs().subtract(1, 'M').startOf('day').utc().toISOString();
        break;
      case TimeRange.beginFiscalYear:
        filter.min = dayjs(fiscalYearStart).startOf('day').utc().toISOString();
        break;
    }

    if (teamFilter && (isAdministrator || isAnalyst)) {
      const allTeamsId = teams.map(t => t.id);
      filter.createdBy = '';
      filter.teamsId = teamFilter === 'all' ? allTeamsId : [Number(teamFilter)];
    }

    return filter;
  }, [timeRangeFilter, teamFilter, teams]);

  return { activitiesFilter };
};